:root {
  --cream: #fff9e5;
  --brown-black: #8e7e68;
  --blue: #0c8ecb;
  --green: #34b3a5;
  --yellow: #ffb500;
}

html {
  position: relative;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100vw;
  overflow-x: hidden;
}

.loader {
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background: rgb(188, 255, 200);
  z-index: 1000;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  background: linear-gradient(
    0deg,
    rgba(188, 255, 200, 1) 40%,
    rgba(0, 212, 255, 1) 100%
  );
}

.loader > p {
  margin: 0;
}
